<script>
import GameCard from "@/components/Card.vue";

export default {
    name: "",
    components: {GameCard},
    data() {
        return {
            loading: true,
            list: [],
            type:0,
        }
    },
    created() {
        this.type = this.$route.query.gameCategory
        this.getSlotGameList()
    },
    methods: {
        // 1 mago推荐 2 老虎机 3 真人娱乐场 4 游戏节目  5新游戏
        getSlotGameList() {
            let param = {};
            param.gameCategory = Number(this.type) //gamecategory 必填，且不能<1
            param.pageNum = 1
            param.pageSize = 100
            this.$http
                .post(this.host + this.api.uc.gameList, param)
                .then(response => {
                    let resp = response.body
                    if (resp.code == '0000') {
                        resp.data.list.map((item, i) => {
                            // if(type === 5) console.log(item.gameName);
                            // 处理特殊情况，如 The Catfather 需要特殊的图片文件名
                            if (item.gameName.toLowerCase() === 'the catfather') {
                                // 特定文件名的处理
                                item.path = require('@/assets/gameImg/' + 'The Catfather Part II.webp');
                            } else {
                                // 默认情况下根据 gameName 动态加载图片
                                try {
                                    // 使用 require 动态加载图片路径，注意 .webp 结尾
                                    item.path = require(`@/assets/gameImg/${item.gameName}.webp`);
                                } catch (e) {
                                    try {  //第一次匹配失败 转为小写
                                        // console.log('--------------------')
                                        item.path = require(`@/assets/gameImg/${item.gameName.toLowerCase()}.webp`)
                                    } catch (err) {
                                        // console.error(`无法加载图片: ${item.gameName}.webp`);
                                        // 如果图片不存在，设置默认图片
                                        item.path = require('@/assets/gameImg/default.png');
                                    }
                                }
                            }
                        });
                        this.list = resp.data.list
                        this.loading = false

                    } else {
                        this.$Notice.error({
                            title: this.$t("common.tip"),
                            desc: resp.mesg
                        });
                        this.realHeight = '50rem'
                    }
                });
        },
    }
}
</script>

<template>
    <div class="gameAll">
        <div v-if="loading" class="loading">
            <Spin :color="'red'" :size="'large'"/>
        </div>
        <div class="top">
            <div class="l"> {{ ['', 'mago推荐', '老虎机', '真人娱乐场', '游戏节目', '新游戏'][type] }}</div>
            <div class="r">
                <img src="../assets/common/777.png" alt="">
            </div>
        </div>
        <div class="card-box">
            <GameCard v-for="(item,i) in list" :item="item" :key="i">
            </GameCard>
        </div>
    </div>
</template>

<style scoped lang="scss">
.gameAll {
    width: 100%;
    //height: 100%;
    color: #fff;
    display: flex;
    flex-direction: column;

    .loading{
        margin: auto;
        height: 30vh;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .top {
        background-color: #213743;
        display: flex;
        justify-content: space-around;
        height: 80px;

        .l {
            width: 30%;
            line-height: 80px;
            font-size: 20px;
            color: #fff;
            font-weight: 800;
        }

        .r {
            flex: 1;
            height: 100%;
            display: flex;
            justify-content: end;

            & > img {
                height: 100%;
            }
        }
    }
    .card-box{
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        & > div{
            width: 33.33%;
        }
    }
}
</style>
